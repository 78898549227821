<template>
  <div>
    <!-- {{horarioEdit.questionario}} -->

    <div
      class="row"
      v-if="horarioEdit"
    >
      <div class="col-12 col-md-6">
        <div
          class="row"
          style=""
        >
          <div class="col-12 col-sm-6 horarios">
            <div
              class="group"
              style="font-size: 13px !important"
            >
              <header style="width: 83px">
                <i class="fa fa-calendar" /> Data
              </header>
              <content>{{ agendaInfo.data | moment("DD/MM/YYYY") }}</content>
            </div>
          </div>
          <div class="col-6 horarios">
            <div
              class="group"
              style="font-size: 13px !important"
            >
              <header><i class="far fa-clock" /> Horário</header>
              <content>{{ horarioEdit.hora }}</content>
            </div>
          </div>
          <div class="col-12 horarios">
            <div
              class="group"
              style="font-size: 13px !important"
            >
              <header style="width: 83px">
                <i class="fa fa-user-md" /> Médico
              </header>
              <content
                >{{ agendaInfo.EntidadeNome }} -
                {{ agendaInfo.especialidadeNome }}</content
              >
            </div>
          </div>

          <div class="col-12 col-sm-9 mt-3 pr-sm-1">
            <label><i class="fa fa-user" /> Paciente</label>

            <!-- <vue-simple-suggest
              v-model="horarioEdit.pacienteSel"
              :list="pacienteLista"
              :styles="autoCompleteStyle"
              :filter-by-query="false"
              :min-length="3"
              :debounce="200"
              display-attribute="razao_social"
              value-attribute="id"
              @select="selectSugg"
              @blur="selectSuggFind"
              mode="select"
              :controls="{
                selectionUp: [38, 33],
                selectionDown: [40, 34],
                select: [13, 36],
                showList: [40],
                hideList: [27, 35, 13],
              }"
            >
              <input
                v-model="horarioEdit.paciente"
                class="default-input form-control"
                @keypress="pulaCampo"
              />
              <div slot="suggestion-item" slot-scope="{ suggestion }">
                {{ suggestion.razao_social }}
                <small v-if="suggestion.idade"
                  >({{ suggestion.idade }} anos)</small
                >
                <br />
                <small>
                  <div class="d-flex justify-content-between">
                    <small v-if="suggestion.cpf"
                      >Doc.: {{ suggestion.cpf }}</small
                    >
                    <small
                      v-if="
                        suggestion.telefones && suggestion.telefones.length > 0
                      "
                      >Fone: {{ suggestion.telefones[0].telefone }}</small
                    >
                  </div>
                </small>
              </div>
            </vue-simple-suggest> -->
            <select-paciente
              @select="selectSugg"
              @blur="selectSuggFind"
              :tipo="['paciente']"
              :text="horarioEdit.paciente"
              :selecionado="horarioEdit.id_paciente"
              :value="{
                id: horarioEdit.id,
                razao_social: horarioEdit.paciente,
              }"
              @paciente="selectSugg"
              @input="onInput"
              :showBotaoEdit="false"
              :showBotaoAdd="false"
              setNullOnEmpty
              :disabled="travar"
            />
            <!-- {{ horarioEdit.paciente }} | {{ horarioEdit.pacienteSel }} -->
          </div>
          <div class="col-12 col-sm-3 mt-3 pl-sm-1">
            <label><i class="fa fa-clocka" /> Idade</label>
            <b-input
              placeholder="Idade"
              type="number"
              max="130"
              min="0"
              :disabled="travar"
              ref="txtIdade"
              v-model="horarioEdit.idade"
              v-on:keyup.enter="$event.target.nextElementSibling.focus()"
              :name="`idade_horario${horarioEdit.id}_${agendaInfo.id}`"
              :id="`idade_horario${horarioEdit.id}_${agendaInfo.id}`"
              :state="hasError('idade')"
            />
          </div>
          <div class="col-12 col-sm-4 mt-3 pr-sm-1">
            <label><i class="fa fa-phone" /> Telefone</label>
            <b-input
              placeholder="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="horarioEdit.telefone"
              :disabled="travar"
              :name="`idade_horario${horarioEdit.id}_${agendaInfo.id}`"
              :id="`idade_horario${horarioEdit.id}_${agendaInfo.id}`"
              :state="hasError('telefone')"
            />
          </div>
          <div class="col-12 col-sm-4 mt-3 pl-sm-1 pr-sm-1">
            <label><i class="fa fa-phone" /> Telefone 2</label>
            <b-input
              placeholder="Telefone 2"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="horarioEdit.telefone2"
              :disabled="travar"
              :name="`idade_horario${horarioEdit.id}_${agendaInfo.id}2`"
              :id="`idade_horario${horarioEdit.id}_${agendaInfo.id}2`"
              :state="hasError('telefone2')"
            />
          </div>
          <div class="col-12 col-sm-4 mt-3 pl-sm-1">
            <label><i class="far fa-address-card" /> CPF</label>
            <b-input
              placeholder="CPF"
              v-model="horarioEdit.cpf"
              :name="`cpf_horario${horarioEdit.id}_${agendaInfo.id}`"
              :id="`cpf_horario${horarioEdit.id}_${agendaInfo.id}`"
              v-mask="'###.###.###-##'"
              :disabled="travar"
            />
          </div>

          <div
            class="col-12 mt-3 text-left"
            style="font-size: 12px"
          >
            <div class="">
              <b-check
                switch
                v-model="horarioEdit.retorno"
                :value="1"
                :disabled="travar"
                :unchecked-value="0"
              >
                <i class="fa fa-undo" /> Retorno
              </b-check>

              <div
                v-if="horarioEdit.retorno == 1"
                class="d-flex flex-column"
              >
                <label>Selecione a consulta anterior</label>
                <b-button
                  :disabled="horario.consulta == 1 || travar"
                  variant="primary"
                  @click="modalBuscarConsulta"
                  ><i class="fas fa-search"></i> Buscar Consulta</b-button
                >
              </div>
              <div v-if="horarioEdit.retorno == 1">
                <b-form-invalid-feedback
                  :force-show="
                    typeof hasError('retorno') === 'boolean' &&
                    !hasError('retorno')
                  "
                  >Por favor, selecione a consulta anterior ou se responsabilize
                  pelo retorno</b-form-invalid-feedback
                >
                <b-form-valid-feedback
                  :force-show="
                    typeof hasError('retorno') === 'boolean' &&
                    hasError('retorno')
                  "
                  >{{
                    horarioEdit.responsabilizacao_retorno
                      ? "Se responsabilizou pelo retorno"
                      : "Consulta anterior selecionada"
                  }}.</b-form-valid-feedback
                >
              </div>
            </div>
          </div>
          <div
            class="col-12 col-sm-4 mt-3 pr-sm-1"
            style="font-size: 12px"
          >
            <label>
              <b-check
                switch
                v-model="horarioEdit.confirmou"
                :value="1"
                :unchecked-value="0"
                :disabled="travar"
              >
                <i class="fa fa-check" /> Confirmou
              </b-check>
            </label>
          </div>
          <div
            class="col-12 col-sm-4 mt-3 pr-sm-1 pl-sm-1"
            style="font-size: 12px"
          >
            <label>
              <b-check
                switch
                v-model="horarioEdit.procedimento"
                :value="1"
                :unchecked-value="0"
                :disabled="travar"
              >
                <i class="fa fa-stethoscope" /> Procedimento
              </b-check>
            </label>
          </div>
          <div
            class="col-12 col-sm-4 mt-3 text-center pl-sm-1"
            style="font-size: 12px"
          >
            <label>
              <b-check
                switch
                v-model="horarioEdit.compareceu"
                :value="1"
                :unchecked-value="0"
                :disabled="travar"
              >
                <i class="fa fa-user-check" /> Compareceu
              </b-check>
            </label>
          </div>
          <div
            class="col-12 mt-3"
            v-if="
              horarioEdit &&
              typeof horarioEdit.especialidade === 'string' &&
              horarioEdit.especialidade.indexOf('OFTALMO') > -1
            "
          >
            <i class="fa fa-question" /> Questionário
            <table class="table">
              <tr>
                <td>1ª VEZ</td>
                <td>
                  <b-radio-group
                    :options="['Sim', 'Não']"
                    v-model="horarioEdit.questionario.pvez"
                    :disabled="travar"
                  />
                </td>
              </tr>
              <tr>
                <td>Já usa óculos?</td>
                <td>
                  <b-radio-group
                    :options="['Sim', 'Não']"
                    :disabled="travar"
                    v-model="horarioEdit.questionario.usaOculos"
                  />
                </td>
              </tr>
              <tr>
                <td>Gestante/Lactante?</td>
                <td>
                  <b-radio-group
                    :options="['Sim', 'Não']"
                    v-model="horarioEdit.questionario.gestante"
                    :disabled="travar"
                  />
                </td>
              </tr>
              <tr>
                <td>Usa lentes de contato?</td>
                <td>
                  <b-radio-group
                    :options="['Sim', 'Não']"
                    v-model="horarioEdit.questionario.usaLente"
                    :disabled="travar"
                  />
                </td>
              </tr>
              <tr>
                <td>Diabético?</td>
                <td>
                  <b-radio-group
                    :options="['Sim', 'Não']"
                    v-model="horarioEdit.questionario.diabetico"
                    :disabled="travar"
                  />
                </td>
              </tr>
            </table>
          </div>

          <div class="col-12 col-sm-6 mt-3">
            <label><i class="fas fa-bullhorn" /> Tipo de Divugação</label>

            <v-select
              :options="tiposDeDiulgacao"
              :reduce="(tipos) => tipos.id"
              v-model="horarioEdit.id_divulgacao"
              label="nome"
              :disabled="travar"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 mt-3">
            <label><i class="far fa-comment-alt" /> Observações</label>
            <b-textarea
              v-model="horarioEdit.obs"
              :disabled="travar"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <h4 class="text-center"><i class="fa fa-history" /> Histórico</h4>
        <hr />

        <historico
          v-if="bscHistorico.id_paciente || bscHistorico.paciente"
          :paciente_id="bscHistorico.id_paciente"
          :nome="bscHistorico.paciente"
        />
      </div>
      <div
        class="float-left ml-3 mt-3 text-danger"
        v-if="hasChanged"
      >
        <b-icon-info-circle /> Alterações realizadas! Não se esqueça de salvar!
      </div>
      <div class="col-12 text-right">
        <hr />

        <b-btn
          v-if="agendaInfo.anamnese"
          variant="info"
          @click="openPreencherAnamnese"
          class="text-nowrap mx-1 float-left"
          :disabled="travar"
        >
          <i class="fas fa-notes-medical"></i>
          {{
            horarioEdit.questionario && horarioEdit.questionario.id
              ? "Visualizar"
              : "Preencher"
          }}
          Anamnese
        </b-btn>
        <b-button
          variant="info"
          class="float-left"
          :disabled="travar"
          @click="limpar"
        >
          <i class="fa fa-broom" /> Limpar / Desmarcar
        </b-button>

        <b-button
          variant="success"
          class="float-left ml-3"
          @click="sendInfosWPP"
          :disabled="travar"
          v-if="canSendConfirm"
          ><i class="fab fa-whatsapp"></i> Informações Agendamento
        </b-button>
        <b-button
          variant="success"
          class="float-left"
          @click="sendConfirmWPP"
          v-if="canSendConfirm"
          :disabled="travar"
          ><i class="fab fa-whatsapp"></i> Confirmação
        </b-button>

        <b-button
          variant="primary"
          class="mr-3"
          :disabled="travar"
          @click="salvar"
        >
          <i class="fa fa-save" /> Salvar
        </b-button>
        <b-button
          variant="secondary"
          @click="$emit('closeHorario')"
        >
          <i class="fa fa-ban" /> Cancelar
        </b-button>
      </div>
    </div>

    <b-modal
      id="modal-anamnese"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        <div><i class="fas fa-notes-medical"></i> Preencher Anamnese</div>
      </template>

      <anamnese-listagem
        v-if="horarioEdit.questionario"
        :responsavel="objResponsavelAnamnese"
        @salvou="onAnamnese($event)"
        @close="closeAnamnese"
        :paciente="pacienteAnamnese"
        :id="agendaInfo.anamnese"
        :questionarioPreenchido="horarioEdit.questionario"
      />
    </b-modal>

    <b-modal
      id="ModalBuscarConsulta"
      size="lg"
    >
      <BuscarConsulta
        v-if="horarioEdit && horarioEdit.paciente"
        ref="BuscarConsulta"
        :pacienteFiltroProp="horarioEdit.paciente"
        :dataAgenda="agendaInfo.data"
        @consultaSelecionada="onConsultaSelecionada($event)"
        @responsabilizou="onResponsabilizou($event)"
        :soConsultas="true"
      />
      <template #modal-title>
        <i class="fas fa-search"></i> Buscar Consulta
      </template>

      <template #modal-footer="{ cancel }">
        <b-btn
          variant="info"
          class="justify-content-start"
          @click="$refs.BuscarConsulta.responsabilizar()"
          :disabled="travar"
          ><i class="fas fa-people-arrows"></i> Responsabilizar</b-btn
        >

        <b-btn
          variant="primary"
          @click="$refs.BuscarConsulta.salvar()"
          :disabled="travar"
          ><i class="fas fa-save"></i> Salvar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="cancel"
        >
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </template>
    </b-modal>

    <b-modal
      id="modal-wpp"
      hide-footer
      :title="`Enviar ${
        sendType == 'info'
          ? 'informações de agendamento'
          : 'confirmação de consulta'
      } para whatsapp`"
    >
      <b-form-group label="Número">
        <vue-simple-suggest
          :suggestion-click="true"
          v-model="wppNumber"
          :list="telefonesDisponiveis"
          :filter-by-query="true"
          ref="select-num-wpp"
          mode="select"
          :controls="{
            selectionUp: [38, 33],
            selectionDown: [40, 34],
            select: [13, 36],
            showList: [40],
            hideList: [27, 35, 13],
          }"
        >
          <input
            class="form-control optional-custom-input"
            v-model="wppNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="off"
            :id="`txtField${Math.random()}`"
            :name="`txtField${Math.random()}`"
          />
        </vue-simple-suggest>
        <div class="mt-2">
          <label>Mensagem á Enviar</label>
          <b-textarea
            v-model="wppADDText"
            placeholder="Mensagem"
            :disabled="travar"
            :id="`txtField${Math.random()}`"
            rows="8"
          ></b-textarea>
        </div>
        <b-alert
          v-if="wppAlert.show"
          :variant="wppAlert.type"
        >
          {{ wppAlert.message }}
        </b-alert>
        <botoes-salvar-cancelar
          :okText="'Enviar'"
          :okIcon="'fab fa-whatsapp'"
          @salvar="enviarWpp"
          :saving="enviandoWpp"
          @cancelar="() => $bvModal.hide('modal-wpp')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import validador from "../../helpers/Validadores";
//import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import EntidadeLib from "../../libs/EntidadesLib";
import BuscarConsulta from "../common/BuscarConsulta.vue";
import TiposDeDivulgacaoLib from "@/libs/TiposDeDivulgacaoLib.js";
// import AnamneseListagem from "../Anamnese/Listagem/AnamneseListagem.vue";
import Historico from "./Paciente/Historico.vue";
import SelectPaciente from "../common/SelectPaciente.vue";
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import WhatsappService from "../../utils/WhatsappService";
import moment from "moment";
import EmpresasLib from "../../libs/EmpresasLib";

export default {
  components: {
    //  VueSimpleSuggest,
    BuscarConsulta,
    // AnamneseListagem,
    Historico,
    SelectPaciente,
    BotoesSalvarCancelar,
  },
  props: {
    horario: {
      type: Object,
      default: null,
    },
    agendaInfo: {
      type: Object,
      default: null,
    },
  },
  sockets: {},
  async mounted() {
    this.horarioEdit = JSON.parse(JSON.stringify(this.$props.horario));

    //Object.assign({}, this.$props.horario);
    if (
      this.horarioEdit.questionario &&
      typeof this.horarioEdit.questionario === "string"
    ) {
      // console.log(1111);
      this.horarioEdit.questionario = JSON.parse(this.horarioEdit.questionario);
    }

    if (this.horarioEdit.paciente_id) {
      this.bscHistorico.id_paciente = this.horarioEdit.paciente_id;
    }

    // console.log("recebeu obj", this.horarioEdit);

    if (!this.horarioEdit.questionario) {
      this.horarioEdit.questionario = {
        usaOculos: "",
        gestante: "",
        pvez: "",
        usaLente: "",
        diabetico: "",
      };
    }

    // this.$socket.emit("agenda:pacienteLista", { paciente: 1 });
    await this.carregarDivulgacoes();
    this.loaded = true;

    this.busca();

    if (
      this.horarioEdit.user_aberto_id &&
      this.horarioEdit.user_aberto_id > 0 &&
      this.horarioEdit.user_aberto_id !== this.user.id
    ) {
      this.$swal({
        title: "Horário Travado",
        text:
          "Este horário está sendo editado por " +
          this.horarioEdit.usuarioAberto,
        icon: "info",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-eye"></i>  Apenas Visualizar',
        cancelButtonText: '<i class="fa fa-door-open"></i> Fechar',
        denyButtonText: '<i class="fa fa-unlock"></i> Destravar',
        showDenyButton: this.user.isAdmin,
      }).then((r) => {
        console.log(r);
        if (r.isConfirmed) {
          this.travar = true;
        } else if (r.isDismissed) {
          this.$emit("closeHorario");
        } else if (r.isDenied) {
          this.$socket.emit("agenda:travarHorario", {
            horario_id: this.horarioEdit.id,
            user_id: this.user.id,
            type: "destravar",
          });
        }
      });
    } else {
      if (this.horarioEdit && this.horarioEdit.id) {
        this.horarioEdit.user_aberto_id = this.user.id;
        this.$socket.emit("agenda:travarHorario", {
          horario_id: this.horarioEdit.id,
          user_id: this.user.id,
          type: "travar",
        });
      }
    }

    console.log("horario", this.horarioEdit, this.user);
  },

  data() {
    return {
      sendType: "info",
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppADDText: "",
      wppAlert: {
        show: false,
        type: "success",
        message: "",
      },

      travar: false,
      horarioEdit: null,
      validationState: [],
      horarioRetorno: {},
      // hasChanged: false,
      //pacienteLista: [],
      autoCompleteStyle: {
        defaultInput: "form-control",
        suggestions:
          "position-absolute list-group z-1000 auto-complete-suggestion-correction",
        suggetItem: "suggestItem",
        lastFind: "",
      },
      tiposDeDiulgacao: [],
      hasChanged: false,
      loaded: false,
      bscHistorico: {
        id_historico: null,
        paciente: null,
        timer: null,
        id_paciente: null,
      },
      ultimaBusca: "",
    };
  },
  methods: {
    async montaMensagem() {
      console.log("a", this.agendaInfo);
      let enderecoFormatado = "Não informado!";

      if (!this.agendaInfo.endereco) {
        if (!this.agendaInfo.consultorio) {
          let Empresa = await EmpresasLib.get(this.agendaInfo.clinica_id);
          console.log("empresa", Empresa);
          if (
            !Empresa ||
            !Empresa.data ||
            !Empresa.data.enderecos ||
            Empresa.data.enderecos.length == 0
          ) {
            alert("Problema no cadastro da empresa!");
            return;
          }
          // WhatsappService;
          enderecoFormatado = `${Empresa.data.enderecos[0].logradouro}, ${Empresa.data.enderecos[0].numero}, ${Empresa.data.enderecos[0].bairro}, ${Empresa.data.enderecos[0].cidade} - ${Empresa.data.enderecos[0].uf}`;
        } else if (this.agendaInfo.consultorio == 1) {
          enderecoFormatado = `${this.agendaInfo.consultorioEndereco.logradouro}, ${this.agendaInfo.consultorioEndereco.numero}, ${this.agendaInfo.consultorioEndereco.bairro}, ${this.agendaInfo.consultorioEndereco.cidade} - ${this.agendaInfo.consultorioEndereco.uf}`;
        }
      } else {
        enderecoFormatado = this.agendaInfo.endereco;
      }
      let texto = "";

      let googleMaps = `https://www.google.com/maps/search/?api=1&query=${enderecoFormatado.replace(
        / /g,
        "+"
      )}`;

      if (this.sendType == "confirm") {
        texto = "✅ *Confirmação de Presença na Consulta*\n\n";
      } else {
        texto = "✅ *Agendamento Realizado!*\n\n";
      }
      // if (this.sendType == "info") {
      texto +=
        "🗓️ *Data* \n" +
        moment(this.agendaInfo.data).format("DD/MM/YYYY") +
        " ás " +
        this.horarioEdit.hora +
        "\n\n";
      texto += "📍 *Local* \n " + enderecoFormatado + "\n";
      // texto += "\n";
      texto += googleMaps + "\n\n";
      "🩺 *Especialidade* \n" + this.agendaInfo.especialidadeNome + "\n\n";
      texto += "👨‍⚕️ *Médico* \n" + this.agendaInfo.EntidadeNome + "\n\n";
      texto += "*Paciente* \n" + this.horarioEdit.paciente + "\n";
      // }
      if (this.sendType == "confirm") {
        texto += "\n*POSSO CONFIRMAR SUA PRESENÇA NA CONSULTA?*\n";
      }

      // if (this.wppADDText) {
      //   texto += "\n*" + this.wppADDText + "*";
      // }

      this.wppADDText = texto;
    },
    async enviarWpp() {
      if (!this.validar()) {
        return;
      }

      // await this.salvar();

      if (!this.wppNumber || this.wppNumber.length < 15) {
        this.wppAlert = {
          show: true,
          type: "danger",
          message: "Número inválido",
        };
        return;
      }
      this.enviandoWpp = true;

      // console.log("Texto", texto);
      try {
        let res1 = await WhatsappService.enviarTextoSimples(
          this.wppNumber,
          this.wppADDText
        );
        console.log(res1);

        this.$swal({
          title: "Mensagem enviada com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        this.$bvModal.hide("modal-wpp");
      } catch (error) {
        this.$swal({
          title: "Erro ao enviar mensagem",
          icon: "error",
          showConfirmButton: false,
        });
      }
      this.enviandoWpp = false;
    },
    sendInfosWPP() {
      this.sendType = "info";
      this.wppNumber = this.horarioEdit.telefone;
      this.montaMensagem();
      this.$bvModal.show("modal-wpp");
    },
    sendConfirmWPP() {
      this.sendType = "confirm";
      this.wppNumber = this.horarioEdit.telefone;
      this.montaMensagem();

      this.$bvModal.show("modal-wpp");
    },
    busca() {
      // console.log("start HIUST", this.horarioEdit?.id_paciente);
      if (this.ultimaBusca != this.horarioEdit.paciente) {
        this.ultimaBusca = this.horarioEdit.paciente;
        this.bscHistorico.id_paciente = null;
        this.bscHistorico.paciente = null;
        if (this.bscHistorico.timer) {
          clearInterval(this.bscHistorico.timer);
        }
        this.bscHistorico.timer = setTimeout(async () => {
          if (this.horarioEdit) {
            if (!this.horarioEdit.id_paciente && this.horarioEdit.paciente) {
              // console.log("buscando por nome");
              this.ultimaBusca;
              let res = await EntidadeLib.buscaPorNome(
                this.horarioEdit.paciente,
                ["paciente"]
              );
              if (res && res.length > 0) {
                this.bscHistorico.id_paciente = res[0].id;
                this.bscHistorico.paciente = res[0].razao_social;
              }
              // console.log('----',res);
            } else {
              this.bscHistorico.id_paciente = this.horarioEdit.id_paciente;
              this.bscHistorico.paciente = this.horarioEdit.paciente;
            }
          }
          this.$forceUpdate();
          // console.log("SETADO", this.bscHistorico);
        }, 200);
      }
    },
    resinc() {
      this.$emit("reSinc", this.horarioEdit);
      this.$swal(
        "Solicitado!",
        "Re-sincornização solicitado, aguarde de 30 a 40 segundos para confirmar",
        "success"
      );
    },
    pacienteLista(busca) {
      if (busca != this.lastFind) {
        this.lastFind = busca;
        return new Promise(function (resolve, reject) {
          //console.log("start busca", busca);
          let lista = EntidadeLib.buscaPorNome(busca, ["paciente"], 30);
          lista
            .then((res) => {
              //console.log(res);
              resolve(res);
            })
            .catch(() => {
              reject();
            });
        });
      }
    },
    onInput(value) {
      this.horarioEdit.paciente = value;
    },
    onPaciente(paciente) {
      // console.log("onPaciente", paciente);
      this.$forceUpdate();
      this.$nextTick(() => {
        if (paciente && paciente?.id) {
          this.horarioEdit.id_paciente = paciente.id;
        } else {
          this.horarioEdit.id_paciente = null;
        }
      });
    },
    onAnamnese(anamnese) {
      this.$forceUpdate();
      // console.log('recebeu anamnese?', anamnese);
      this.closeAnamnese();
      Object.assign(this.horarioEdit.questionario, anamnese);
    },

    openPreencherAnamnese() {
      this.$bvModal.show("modal-anamnese");
    },
    closeAnamnese() {
      this.$bvModal.hide("modal-anamnese");
    },
    async carregarDivulgacoes() {
      try {
        this.tiposDeDiulgacao = await TiposDeDivulgacaoLib.get();
      } catch (error) {
        this.showToast("error", "Erro ao carregar tipos de divulgação");
      }
    },
    onConsultaSelecionada(consulta) {
      if (this.horarioEdit.responsabilizacao_retorno) {
        this.horarioEdit.responsabilizacao_retorno = 0;
        this.horarioEdit.id_usuario_responsabilizacao_retorno = null;
      }

      this.$bvModal.hide("ModalBuscarConsulta");
      this.horarioRetorno = consulta;

      this.horarioEdit.retorno_venda_item_id = this.horarioRetorno.id;
      this.addOrUpdateStateError(
        "retorno",
        !validador.string.IsNullOrEmpty(this.horarioEdit.retorno_venda_item_id)
      );
    },
    onResponsabilizou(id) {
      if (this.horarioEdit.retorno_venda_item_id) {
        this.horarioEdit.retorno_venda_item_id = null;
      }
      this.$bvModal.hide("ModalBuscarConsulta");
      this.horarioEdit.responsabilizacao_retorno = 1;
      this.horarioEdit.id_usuario_responsabilizacao_retorno = id;
      this.addOrUpdateStateError(
        "retorno",
        !validador.string.IsNullOrEmpty(
          this.horarioEdit.id_usuario_responsabilizacao_retorno
        )
      );
    },
    modalBuscarConsulta() {
      this.$bvModal.show("ModalBuscarConsulta");
    },
    addOrUpdateStateError(campo, value, message = "Verifique os campos") {
      let error = this.validationState.find((x) => x.campo === campo);
      if (!error) {
        error = { campo, value, message };
        this.validationState.push(error);
      }
      error.value = value;
    },
    hasError(campo) {
      if (campo) {
        let cp = this.validationState.find((x) => x.campo === campo);
        return cp ? cp.value : null;
      } else {
        return this.validationState.filter((x) => x.value === false).length > 0;
      }
    },
    validar() {
      this.addOrUpdateStateError(
        "paciente",
        !validador.string.IsNullOrEmpty(this.horarioEdit.paciente),
        "Informe o paciente"
      );
      this.addOrUpdateStateError(
        "idade",
        !validador.string.IsNullOrEmpty(this.horarioEdit.idade),
        "Informe a idade do paciente"
      );
      // this.addOrUpdateStateError(
      //   "cpf",
      //   !validador.string.IsNullOrEmpty(this.horarioEdit.cpf)
      // );
      this.addOrUpdateStateError(
        "telefone",
        !validador.string.IsNullOrEmpty(this.horarioEdit.telefone),
        "Informe pelo menos 1 telefone do paciente"
      );
      if (this.horarioEdit.retorno) {
        if (this.horarioEdit.responsabilizacao_retorno) {
          this.addOrUpdateStateError(
            "retorno",
            !validador.string.invalidNumber(
              this.horarioEdit.id_usuario_responsabilizacao_retorno
            ),
            "Para marcar um retorno você precisa informar a consulta ou se responsabilizar."
          );
        } else {
          this.addOrUpdateStateError(
            "retorno",
            !validador.string.invalidNumber(
              this.horarioEdit.retorno_venda_item_id
            ),
            "Para marcar um retorno você precisa informar a consulta ou se responsabilizar."
          );
        }

        // if (typeof this.horarioRetorno.id === "undefined") {
        //   this.showToast(
        //     "error",
        //     "Para marcar um retorno, você precisa se responsabilizar ou selecionar a consulta anterior do paciente!",
        //     5500
        //   );
        //   return;
        // }
      } else {
        this.addOrUpdateStateError("retorno", true);
      }

      const errors = this.validationState
        .filter((x) => !x.value)
        .map((x) => x.message);
      if (errors.length > 0) {
        let errDesc = errors.reduce((it, vl) => {
          it += vl + " <hr />";
          return it;
        }, "");
        // console.log(errDesc);
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
          icon: "error",
        });
        return false;
      }
      return true;
    },

    salvar(close = true) {
      if (this.horarioEdit) {
        if (
          this.horarioEdit &&
          typeof this.horarioEdit.especialidade === "string" &&
          this.horarioEdit.especialidade.indexOf("OFTALMO") > -1 &&
          (!this.horarioEdit.questionario.pvez ||
            !this.horarioEdit.questionario.usaOculos ||
            !this.horarioEdit.questionario.usaLente ||
            !this.horarioEdit.questionario.gestante ||
            !this.horarioEdit.questionario.diabetico)
        ) {
          this.$swal("Atenção", "Preencha o questionário completo!", "error");
          return;
        }
        if (
          !this.horarioEdit.id_divulgacao ||
          this.horarioEdit.id_divulgacao <= 0
        ) {
          this.$swal("Atenção", "Preencha o tipo de divulgação!", "error");
          return;
        }
        // console.log("error", this.validationState, this.hasError(null));
        if (this.validar()) {
          for (let k of Object.keys(this.horarioEdit)) {
            if (typeof this.horarioEdit[k] == "string") {
              this.horarioEdit[k] = this.horarioEdit[k].toUpperCase();
            }
          }
          this.$emit("atualizaHorario", this.horarioEdit, close);
        }
      }
    },
    selectSugg(e) {
      // console.log(e);

      if (e) {
        this.fillSelected(e);
        this.lastFind = e.razao_social;
        this.horarioEdit.paciente = e.razao_social;
        this.horarioEdit.id_paciente = e.id;
        this.horarioEdit.pacienteSel = e;
        this.$refs.txtIdade.focus();
      } else {
        this.horarioEdit.id_paciente = null;
        this.horarioEdit.pacienteSel = null;
      }
    },
    fillSelected(e) {
      if (e) {
        if (e.id) {
          this.horarioEdit.id_paciente = e.id;
        }
        // if (e.telefones && e.telefones.length > 0) {
        //   // let telefone = e.telefones[0].telefone;
        //   // this.horarioEdit.telefone =
        //   //   typeof telefone === "string" && telefone.trim().length >= 9
        //   //     ? telefone
        //   //     : this.horarioEdit.telefone;
        // }
        // if (e.telefones && e.telefones.length > 1) {
        //   let telefone = e.telefones[1].telefone;
        //   this.horarioEdit.telefone2 = telefone
        //     ? telefone
        //     : this.horarioEdit.telefone2;
        // }
        if (e.idade) {
          this.horarioEdit.idade = e.idade;
        }
        if (e.cpf) {
          this.horarioEdit.cpf = e.cpf;
        }
        // this.$refs.txtIdade.focus();
      } else {
        this.horarioEdit.telefone = "";
        this.horarioEdit.idade = "";
        this.horarioEdit.cpf = "";
      }
    },
    async selectSuggFind() {
      // console.log("selectSuggFind");
      if (this.horarioEdit.paciente && this.horarioEdit.paciente != "") {
        if (
          this.horarioEdit.pacienteSel &&
          this.horarioEdit.pacienteSel.razao_social != this.horarioEdit.paciente
        ) {
          this.horarioEdit.id_paciente = null;
          this.horarioEdit.pacienteSel = null;
          let find = (
            await this.pacienteLista(this.horarioEdit.paciente)
          ).filter(
            (x) =>
              x.razao_social &&
              x.razao_social.toLowerCase() ==
                this.horarioEdit.paciente.toLowerCase()
          );
          if (find.length >= 0) {
            this.horarioEdit.id_paciente = find[0].id;
            this.horarioEdit.pacienteSel = find[0];
            this.horarioEdit.paciente = find[0].razao_social;
            this.fillSelected(find[0]);
            // this.$refs.txtIdade.focus();
          }
        } else {
          this.horarioEdit.id_paciente = this.horarioEdit.pacienteSel.id;
          this.fillSelected(this.horarioEdit.pacienteSel);
          // this.$refs.txtIdade.focus();
        }
      }
    },
    pulaCampo(e) {
      e;
    },
    limpar() {
      this.$swal
        .fire({
          title: "Confirmação",
          text: "Você tem certeza que deseja limpar este horário?",
          icon: "question",
          confirmButtonText: "Sim limpar!",
          cancelButtonText: "Não",
          showCancelButton: true,
        })
        .then((conf) => {
          if (conf.isConfirmed) {
            for (let k of Object.keys(this.horarioEdit)) {
              if (
                k != "id" &&
                k != "id_agenda" &&
                k != "horario" &&
                k != "retorno_venda_item_id" &&
                k != "id_usuario_editou" &&
                k != "order"
              ) {
                // console.log(k);

                this.horarioEdit[k] =
                  this.horarioEdit[k] && typeof this.horarioEdit[k] === "object"
                    ? {}
                    : null;
              }
            }
            this.$emit("atualizaHorario", this.horarioEdit);
          }
        });
    },
  },
  watch: {
    "horarioEdit.questionario": function () {
      if (
        this.horarioEdit.questionario &&
        typeof this.horarioEdit.questionario === "string"
      ) {
        // console.log(1111);
        this.horarioEdit.questionario = JSON.parse(
          this.horarioEdit.questionario
        );
      }
    },
    "horarioEdit.retorno"(newVal) {
      if (newVal === 0) {
        this.horarioEdit.retorno_venda_item_id = null;
        this.horarioEdit.id_usuario_responsabilizacao_retorno = null;
        this.horarioEdit.responsabilizacao_retorno = 0;
        this.addOrUpdateStateError("retorno", true);
      } else {
        this.addOrUpdateStateError(
          "retorno",
          !validador.string.IsNullOrEmpty(
            this.horarioEdit.retorno_venda_item_id
          ) ||
            !validador.string.IsNullOrEmpty(
              this.horarioEdit.id_usuario_responsabilizacao_retorno
            )
        );
      }
    },
    horarioEdit: {
      deep: true,
      handler() {
        if (this.loaded === true && this.horarioEdit.id) {
          this.hasChanged = true;
          this.busca();
        }
      },
    },
  },
  computed: {
    canSendConfirm() {
      let canSend = true;
      if (this.agendaInfo?.consultorio) {
        canSend = true;
      } else {
        if ([16, 12, 13, 10, 14].includes(this.agendaInfo?.clinica_id)) {
          canSend = false;
        }
      }

      return canSend;
    },
    objResponsavelAnamnese() {
      return {
        nome: this.horarioEdit?.usuario_anamnese,
        data: this.horarioEdit?.dt_respondeu_anamnese,
      };
    },
    pacienteAnamnese() {
      return {
        nome: this.horarioEdit?.paciente,
        idade: this.horarioEdit?.idade,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/agenda.scss";
</style>
